import Network from "./Network.js";

export default class FileHelper {
    /**
     * Retrieve a file name based on it's path
     * @param path {string}
     * @returns {string|null}
     */
    static getFileName(path) {
        let folders = path.split(`/`);
        if (folders.length > 1) {
            path = folders[folders.length - 1];// takes only the last folder name
        }
        let names = path.split(`.`);
        if (names.length === 1) {
            return names[0];
        }
        path = ``;
        for (let i = 0; i < names.length - 1; i++) {
            if (i > 0) {
                path += `.`;
            }
            path += names[i];
        }
        return path;
    }

    /**
     * Retrieve file extension based on it`s complete path
     * @param path {string}
     * @returns {string|null}
     */
    static getFileExtension(path) {
        let names = path.split(`.`);
        if (names.length > 1) {
            return names[names.length - 1].toLowerCase();
        } else {
            return null;
        }
    }

    /**
     * Retrieve file path (without file name) based on it's complete path
     * @param path {string}
     * @returns {string}
     */
    static getFilePath(path) {
        let folders = path.split(`/`);
        path = ``;
        for (let i = 0; i < folders.length - 1; i++) {
            if (i > 0) {
                path += `/`;
            }
            path += folders[i];
        }
        return path;
    }

    /**
     * Retrieve the file name and extension
     * @param path {string}
     * @returns {string}
     */
    static getFileCompleteName(path) {
        return this.getFileName(path) + `.` + this.getFileExtension(path);
    }

    /**
     * Return a simplified type based on the file extension
     * @return {String} image|video|office|audio|storyboard
     */
    static getFileType(fileName) {
        const extension = this.getFileExtension(fileName);
        switch (extension) {
            case `bmp`:
            case `jpg`:
            case `jpeg`:
            case `gif`:
            case `webp`:
            case `png`:
            case `svg`:
                return `image`;
            case `pdf`:
                return `pdf`;
            case `doc`:
            case `docx`:
            case `xls`:
            case `xlsx`:
            case `ppt`:
            case `pptx`:
                return `office`;
            case `webm`:
            case `mov`:
            case `mp4`:
                return `video`;
            case `wav`:
            case `ogg`:
            case `mp3`:
                return `audio`;
            case `zip`:
            case `eil`:
            case `eis`:
                return `storyboard`;
            default:
                return `unknown`;
        }
    }

    static loadImageFromFile(imageFile) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onloadend = (evt) => { // When loaded, display image preview
                this.imageSrc = evt.target.result;

                FileHelper.loadImageSrc(this.imageSrc)
                    .then(image => {
                        resolve(image);
                    })
                    .catch(e => {
                        reject(e);
                    });
            };
            reader.onerror = (evt) => { // When loaded, display image preview
                reader.abort();
                reject(new DOMException(`Problem parsing input file.`));
            };
            reader.readAsDataURL(imageFile);
        });
    }

    static loadImageSrc(src) {
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.crossOrigin = `anonymous`;
            image.addEventListener(`load`, (evt) => {
                resolve(evt.target);
            });
            image.addEventListener(`error`, () => {
                reject(new DOMException(`Problem loading image.`));
            });
            image.src = src;
        });
    }

    static loadImageAsBase64(url) {
        return new Promise((resolve, reject) => {
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        let image = new Image();
                        image.src = reader.result;
                        resolve(image)
                    };
                }).catch(e => {
                reject(e);
            });
        });
    }

    static loadImageWithProxyAsBase64(url) {
        return new Promise((resolve, reject) => {
            // Use Proxy to fix CORS issues
            Network.post(`/proxy`, {
                url: url,
                responseType: `arraybuffer`
            }, {responseType: `arraybuffer`, system_api: true})
                .then(resp => {
                    let image = new Image();
                    image.src = `data:${resp.headers[`content-type`]};base64,${Buffer.from(resp.data).toString(`base64`)}`;
                    resolve(image);
                }).catch(e => {
                reject(e);
            });
        });
    }

    static loadVideoFromFile(videoFile, thumbnailCallback) {
        let videoSrc = URL.createObjectURL(videoFile);
        return this.loadVideoMetadata(videoSrc, thumbnailCallback)
    }

    static loadVideoMetadata(src, thumbnailCallback) {
        return new Promise((resolve, reject) => {
            let video = document.createElement(`video`);
            video.preload = `auto`;
            video.crossOrigin = `anonymous`;
            video.addEventListener(`loadedmetadata`, (evt) => {
                evt.target.width = evt.target.videoWidth;
                evt.target.height = evt.target.videoHeight;
                resolve(evt.target);
            });
            video.addEventListener(`canplay`, () => {
                if (thumbnailCallback) {
                    thumbnailCallback(video, video.width, video.height);
                }
            });
            video.addEventListener(`error`, () => {
                reject(new DOMException(`Problem loading video.`));
            });
            video.src = src;
        });
    }

    static createSVGImage(image, width, height) {
        if (!image) {
            return null;
        }
        const canvas = document.createElement(`canvas`);
        if (!width) {
            width = image.width;
        }
        if (!height) {
            height = image.height;
        }
        let context = canvas.getContext(`2d`);
        canvas.width = width;
        canvas.height = height;
        context.drawImage(image, 0, 0);
        const base64Image = canvas.toDataURL(`image/webp`);
        return `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}"><image class="fixed" href="${base64Image}" x="0" y="0" width="${width}" height="${height}"></image></svg>`;
    }

    static createThumbnail(imageOrVideo, thumbnailSize = 300, width, height, iconSettings) {
        if (!imageOrVideo) {
            return null;
        }
        const canvas = document.createElement(`canvas`);
        if (!width) {
            width = imageOrVideo.width || imageOrVideo.videoWidth;
        }
        if (!height) {
            height = imageOrVideo.height || imageOrVideo.videoHeight;
        }
        let context = canvas.getContext(`2d`);
        const sizeRatio = FileHelper.getSquareSizeWithRatio(thumbnailSize, width, height);
        canvas.width = sizeRatio.width;
        canvas.height = sizeRatio.height;
        context.drawImage(imageOrVideo, 0, 0, width, height, 0, 0, canvas.width, canvas.height);
        if (iconSettings) {
            const iconSize = iconSettings.size || 64;
            context.drawImage(iconSettings.icon, canvas.width / 2 - iconSize / 2, canvas.height / 2 - iconSize / 2, iconSize, iconSize);
        }
        return canvas.toDataURL(`image/webp`);
    }

    /**
     * Return an object size while keeping ratio
     * @param maxWidth {int} width maximum available
     * @param maxHeight {int} height maximum available
     * @param width {int} the current width of the image
     * @param height {int} the current height of the image
     * @returns {{int, int}}
     */
    static getRectangleSizeWithRatio(maxWidth, maxHeight, width, height) {
        let ratio = Math.min(width / maxWidth, height / maxHeight);
        if (width / maxWidth > height / maxHeight) {
            return {
                left: (width - maxWidth * ratio) / 2,
                top: 0,
                width: maxWidth * ratio,
                height: height
            };
        } else {
            return {
                left: 0,
                top: (height - maxHeight * ratio) / 2,
                width: width,
                height: maxHeight * ratio
            };
        }
    }

    /**
     * Return an object size while keeping ratio
     * @param maxSize {int} size maximum available
     * @param width {int} the current width
     * @param height {int} the current height
     * @returns {{int, int}}
     */
    static getSquareSizeWithRatio(maxSize, width, height) {
        if (width > height) {
            return {
                width: maxSize,
                height: maxSize / (width / height)
            };
        } else {
            return {
                width: maxSize / (height / width),
                height: maxSize
            };
        }
    }
}
