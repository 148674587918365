export default class IframeHelper {
    static get IFRAME_PRESETS() {
        return {
            /*
            General
             */
            'media-preview': {
                glyph: `fas fa-image`,
                icon: `image`,
                title: `Preview|Aperçu`,
                url: `{{url}}`,
                fullscreen: true,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: true,
                size: `lg`
            },
            'account-creation': {
                glyph: `fas fa-user-plus`,
                icon: `user`,
                title: `New User Account|Nouveau compte utilisateur`,
                url: `${window.env.VUE_APP_URLS__CONTROL_PANEL}/#/user/create?dealerid={{dealerid}}`,
                fullscreen: true,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: false,
                size: `md`
            },
            'account-properties': {
                glyph: `fas fa-user`,
                icon: `user`,
                title: `User Account Properties|Propriétés du compte utilisateur`,
                url: `${window.env.VUE_APP_URLS__CONTROL_PANEL}/#/user/{{id}}`,
                fullscreen: true,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: false,
                size: `md`
            },
            'ticket-creation': {
                glyph: `fas fa-clipboard-list-check`,
                icon: `clipboard`,
                title: `New Ticket|Nouveau ticket`,
                url: `${window.env.VUE_APP_URLS__CONTROL_PANEL}/#/ticket/create?dealerid={{dealerid}}`,
                fullscreen: false,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: false,
                size: `lg`
            },
            'ticket-properties': {
                glyph: `fas fa-clipboard-list-check`,
                icon: `clipboard`,
                title: `Ticket Properties|Propriétés du ticket`,
                url: `${window.env.VUE_APP_URLS__CONTROL_PANEL}/#/ticket/{{id}}`,
                fullscreen: false,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: false,
                size: `lg`
            },
            /*
            Loyalty
             */
            'loyalty-receipt': {
                glyph: `fas fa-receipt`,
                icon: `receipt`,
                title: `Receipt Properties|Propriétés du reçu`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/loyalty/receipt/{{id}}`,
                fullscreen: true,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: true,
                size: `lg`
            },
            'loyalty-member': {
                glyph: `fas fa-user`,
                icon: `user`,
                title: `Member Properties|Propriétés du membre`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/loyalty/member/{{id}}`,
                fullscreen: true,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: true,
                size: `lg`
            },
            /*
            Digital Signage
             */
            'file-properties': {
                glyph: `fas fa-file-image`,
                icon: `file`,
                title: `File Properties|Propriétés du fichier`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/digital-signage/file/edit/{{id}}`,
                fullscreen: false,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: true,
                size: `lg`
            },
            'folder-properties': {
                glyph: `fas fa-folder`,
                icon: `folder`,
                title: `Folder Properties|Propriétés du dossier`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/digital-signage/folder/{{id}}`,
                fullscreen: false,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: false,
                size: `md`
            },
            'player-creation': {
                glyph: `fas fa-tv`,
                icon: `player-creation`,
                title: `New Player|Nouveau diffuseur`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/digital-signage/player-creation?dealerid={{dealerid}}`,
                fullscreen: false,
                allowFullscreen: true,
                fillHeight: false,
                height: `825px`,
                size: `sm`
            },
            'player-properties': {
                glyph: `fas fa-tv`,
                icon: `player`,
                title: `Player Properties|Propriétés du diffuseur`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/digital-signage/player/{{id}}`,
                fullscreen: false,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: true,
                size: `lg`
            },
            'player-preview': {
                glyph: `fas fa-eye`,
                icon: `player`,
                title: `Player Preview|Aperçu du diffuseur`,
                url: `${window.env.VUE_APP_URLS__PLAYER}/?preview=player&id={{id}}&cache=${generateGuid()}`,
                fullscreen: false,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: false,
                size: `md`
            },
            'playlist-creation': {
                glyph: `fas fa-clapperboard-play`,
                icon: `playlist-content-creation`,
                title: `New Playlist|Nouvelle liste de lecture`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/digital-signage/playlist-creation?dealerid={{dealerid}}`,
                fullscreen: false,
                allowFullscreen: true,
                allowOpenExternal: false,
                fillHeight: false,
                size: `xs`
            },
            'playlist-properties': {
                glyph: `fas fa-clapperboard-play`,
                icon: `playlist-content`,
                title: `Playlist Properties|Propriétés de la liste de lecture`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/digital-signage/playlist/{{id}}`,
                fullscreen: false,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: true,
                size: `lg`
            },
            'widget-creation': {
                glyph: `fas fa-sunny`,
                icon: `widgets/apps`,
                title: `New Widget|Nouveau widget`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/digital-signage/widget-creation/{{type}}/{{id}}`,
                fullscreen: true,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: true,
                size: `xl`
            },
            'widget-data-collection-creation': {
                glyph: `fas fa-database`,
                icon: `database-creation`,
                title: `New Widget Data Collection|Nouvelle collection de données pour widget`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/digital-signage/widget-data/collection-creation`,
                fullscreen: false,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: false,
                size: `sm`
            },
            'widget-data-collection-properties': {
                glyph: `fas fa-database`,
                icon: `database`,
                title: `Widget Data Collection Properties|Propriétés de la collection de données`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/digital-signage/widget-data/collection/{{type}}/{{id}}`,
                fullscreen: true,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: true
            },
            /*
            WiFi
             */
            'router-creation': {
                glyph: `fas fa-router`,
                icon: `router-creation`,
                title: `New Controller|Nouveau contrôleur`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/wifi/inventory/router-creation?dealerid={{dealerid}}`,
                fullscreen: false,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: false,
                size: `sm`
            },
            'router-properties': {
                glyph: `fas fa-router`,
                icon: `router`,
                title: `Router Properties|Propriétés du routeur`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/wifi/inventory/router/{{id}}`,
                fullscreen: true,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: true,
                size: `lg`
            },
            'network-equipment-creation': {
                glyph: `fak fa-access-point`,
                icon: `network-ap-creation`,
                title: `New Equipment|Nouvel équipement`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/wifi/inventory/equipment-creation?dealerid={{dealerid}}`,
                fullscreen: false,
                allowFullscreen: true,
                fillHeight: true,
                size: `sm`
            },
            'network-equipment-properties': {
                glyph: `fak fa-access-point`,
                icon: `network-ap`,
                title: `Equipment Properties|Propriétés de l'équipmement`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/wifi/inventory/equipment/{{id}}`,
                fullscreen: false,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: true,
                size: `lg`
            },
            'network-configuration': {
                glyph: `fak fa-solid-wifi-gear`,
                icon: `access-point`,
                title: `Network Configuration|Paramètres réseaux`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/wifi/network-configuration/{{dealerid}}`,
                fullscreen: false,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: true,
                size: `lg`
            },
            'wifi-module': {
                glyph: `fas fa-photo-film`,
                icon: `image`,
                title: `Login Visual|Visuel de connexion`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/wifi/visual/{{id}}`,
                fullscreen: false,
                allowFullscreen: true,
                allowOpenExternal: true,
                fillHeight: true,
                size: `lg`
            },
            'wifi-user-profile': {
                glyph: `fas fa-user`,
                icon: `user`,
                title: `WiFi User Profile|Profil utilisateur WiFi`,
                url: `${window.env.VUE_APP_URLS__EYEINTELLIGENCE}/#/wifi/crm/user/{{id}}`,
                fullscreen: false,
                allowFullscreen: true,
                fillHeight: true,
                size: `lg`
            },
            // CMS
            'cms-page-preview': {
                glyph: `fas fa-file-text`,
                title: `Page Preview|Aperçu de la page`,
                url: `${window.env.VUE_APP_URLS__CMS}/cms/preview/website/{{websiteId}}/{{id}}`,
                fullscreen: true,
                allowOpenExternal: true
            },
            'cms-page-creation': {
                glyph: `fas fa-file-circle-plus`,
                title: `New Page|Nouvelle page`,
                url: `${window.env.VUE_APP_URLS__CMS}/cms/editor/website/{{id}}/pages/creation`,
                fullscreen: false,
                allowFullscreen: false,
                fillHeight: true
            },
            'cms-website-creation': {
                glyph: `fas fa-plus`,
                title: `Website Creation|Créer un site`,
                url: `${window.env.VUE_APP_URLS__CMS}/cms/editor/website/creation/{{dealerid}}`,
                fullscreen: false,
                allowFullscreen: false,
                fillHeight: true,
                size: `xs`
            },
            'cms-website-settings': {
                glyph: `fas fa-cog`,
                title: `Website Settings|Paramètres du site`,
                url: `${window.env.VUE_APP_URLS__CMS}/cms/editor/website/{{id}}/settings`,
                fullscreen: false,
                allowFullscreen: false,
                fillHeight: true
            },
            'image-360-preview': {
                glyph: `fas fa-cube`,
                title: `360 Image Preview|Aperçu de l'image 360`,
                url: `{{url}}`,
                fullscreen: true,
                allowOpenExternal: true
            }
        }
    }

    static getPreset(name) {
        return this.IFRAME_PRESETS[name];
    }
}
