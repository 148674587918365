<template>
    <component :is="tagName" class="file-input" @drop="onFileDropped"
          :class="['size-' + size, theme === 'light' ? 'theme-light' : 'theme-dark', {'inline': inline, 'no-border': noBorder}]">
        <input ref="fileInput" class="invisible" @change="onFileChanged" style="position: absolute;" type="file" :accept="accept"/>
        <div class="image-placeholder" @click="open()">
            <b-img class="preview" :rounded="rounded" :src="previewImage || iconImage" :class="contain ? 'contain' : 'cover'" :style="imageStyle"/>
            <template v-if="!readonly && !hideHint">
                <div class="form-help">
                    <slot><span v-tr>Select a local file|Sélectionner un fichier</span></slot>
                </div>
            </template>
        </div>
        <div class="mt-2">
            <slot name="buttons-before"></slot>
            <b-btn @click="open" variant="primary">
                <i class="fas fa-folder-open mr-1"/>
                <span v-tr>Change|Changer</span>
            </b-btn>
            <slot name="buttons"></slot>
            <b-btn v-if="(allowEmpty || clearButton) && $refs.fileInput && $refs.fileInput.value" class="ml-2" @click="clear" v-tr>Remove|Enlever</b-btn>
        </div>
    </component>
</template>

<script>
import swal from "sweetalert";
import {Drop} from "vue-drag-drop"
import FileHelper from "../helpers/FileHelper.js";

export default {
    name: `e-file-input`,
    components: {Drop},
    props: {
        accept: String,
        tagName: {type: String, default: `drop`},
        multiple: {type: Boolean},
        type: {type: String, default: `file`},
        extensionsAccepted: {type: Array},
        base64: {type: Boolean},
        contain: {type: Boolean},
        inline: {type: Boolean},
        rounded: {type: Boolean},
        value: [String, File],
        allowEmpty: {type: Boolean},
        clearButton: {type: Boolean}, // compatibility prop similar to allowEmpty
        defaultImage: String,
        size: {type: String, default: `md`}, // sm | md | lg |xl
        readonly: {type: Boolean},
        imageStyle: {type: Object, default: null},
        hideHint: {type: Boolean},
        noBorder: {type: Boolean},
        theme: {type: String, default: `dark`},
        icon: {type: String}
    },
    data() {
        return {
            preview: null
        }
    },
    computed: {
        previewImage() {
            if (this.defaultImage && !this.preview) {
                return this.defaultImage;
            } else {
                return this.preview;
            }
        },
        iconImage() {
            if (this.icon) {
                return this.icon;
            } else if (this.type === `image`) {
                return this.getPublicIcon(`image`);
            } else {
                return this.getPublicIcon(`hard-drive`);
            }
        }
    },
    mounted() {
        if (this.base64) {
            this.preview = this.value;
        } else {
            this.preview = null;
        }
        this.$refs.fileInput.value = ``;

        if (this.multiple && this.base64) {
            throw new Error(`Cannot use base64 for multiple file input.`);
        }
        if (this.type !== `image` && this.base64) {
            throw new Error(`Cannot use base64 for type '${this.type}'.`);
        }
    },
    methods: {
        clear() {
            this.reset();
            this.$emit(`input`, null);
            this.$emit(`change`, null);
        },
        reset() {
            this.preview = null;
            this.$refs.fileInput.value = null;
        },
        onFileChanged() {
            const files = this.$refs.fileInput.files;
            this.addFiles(files);
        },
        onFileDropped(item, event) {
            event.preventDefault();
            const files = event.dataTransfer.files;
            this.addFiles(files);
        },
        async addFiles(files) {
            if (files.length === 0) {
                this.$emit(`change`, null);
            }
            if (!this.multiple) {
                let file = files[0];
                if (!file)
                    return;

                if (this.extensionsAccepted) {
                    if (this.extensionsAccepted.indexOf(FileHelper.getFileExtension(file.name)) < 0) {
                        swal(this.tr(`Invalid file|Fichier non valide`), this.tr(`This file type is not supported|Ce type de fichier n'est pas supporté`), `warning`);
                        // file not accepted
                        return;
                    }
                }

                if (this.base64) {
                    file = await this.toBase64(file);
                    this.preview = file;
                } else {
                    this.preview = URL.createObjectURL(file);
                }
                this.$emit(`input`, file);
                this.$emit(`change`, file);
            } else {
                this.$emit(`input`, files);
                this.$emit(`change`, files);
            }
        },
        async toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        open() {
            if (!this.readonly) {
                this.$refs.fileInput.click();
            } else {
                if (this.defaultImage) {
                    window.open(this.defaultImage, this.tr(`Preview|Aperçu`), `top=0,left=0,width=1280,height=720,resizable=yes,status=no,toolbar=no`);
                }
            }
        }
    },
    watch: {
        value() {
            if (this.base64) {
                this.preview = this.value;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.file-input {
    display: block;
    border-radius: 3px;
    max-height: 180px;
    color: white;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    position: relative;

    background: rgba(255, 255, 255, 0.1);
    border: 2px dashed rgba(255, 255, 255, 0.4);
    width: 100%;
    text-align: center;
    padding: 10px;
    transition: 150ms;
    margin-bottom: 20px;

    &.no-border {
        border: none !important;
        box-shadow: none !important;
        background: none !important;
    }

    &:hover {
        background: rgba(255, 255, 255, 0.2);
        border: 2px dashed rgba(255, 255, 255, 0.6);
    }

    &:active {
        background: rgba(0, 0, 0, 0.1);
    }

    img.icon {
        position: absolute;
        bottom: 5px;
        right: 5px;
        height: 48px;
        opacity: 0.5;
        border-radius: 4px;
    }

    img.preview {
        max-width: 80%;
        max-height: 100px;
        margin: 0 auto;
        display: block;

        &.contain {
            object-fit: contain !important;
        }

        &.cover {
            object-fit: cover !important;
        }
    }

    &.size-xl {
        max-height: 500px;

        img.preview {
            max-height: 400px;
        }

        .form-help {
            margin-top: 10px;
        }
    }

    &.size-lg {
        max-height: 330px;

        .form-help {
            margin-top: 5px;
        }

        img.preview {
            max-height: 250px;
        }
    }

    &.size-sm {
        max-height: 125px;

        img.preview {
            height: 65px;
            object-fit: contain;
        }
    }

    &.inline {
        max-height: 120px;
        display: flex;
        align-items: center;

        .form-help {
            display: none;
        }

        .image-container {
            flex: 1;
        }

        img.preview {
            max-width: initial;
            max-height: 100px;
            border-radius: 4px;
        }
        .btn {
            margin: 0 10px !important;
        }
    }

    &.theme-light {
        color: rgba(black, 0.9);
        background: rgba(0, 0, 0, 0.05);
        border: 2px dashed rgba(0, 0, 0, 0.2);

        &.no-border {
            border: none !important;
            box-shadow: none !important;
            background: none !important;
        }

        &:hover {
            background: rgba(0, 0, 0, 0.1);
            border: 2px dashed rgba(0, 0, 0, 0.3);
        }

        &:active {
            background: rgba(255, 255, 255, 0.2);
        }
    }
}
</style>
