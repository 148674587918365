<template>
    <div class="e-iframe-dialog">
        <dialog ref="dialog" :class="['size-' + iframeSize, { 'visible': visible, 'minimized': minimized, 'fullscreen': isFullscreenEnabled, 'fill-height': iframeFillHeight }]">
            <div v-show="!minimized" class="overlay"></div>
            <div class="content" :style="contentStyle">
                <div class="header" v-if="!noHeader">
                    <slot name="header">
                        <div class="title">
                            <slot name="title">
                                <h4 @dblclick="urlVisible = true">
                                    <b-img class="favicon mr-1" v-if="iframeIcon" :src="iframeIcon"/>
                                    <i class="mr-1" v-else-if="iframeGlyph" :class="iframeGlyph"/>
                                    {{iframeTitle|tr}}</h4>
                            </slot>
                        </div>
                        <div class="actions">
                            <slot name="header-actions"></slot>
                        </div>
                        <div class="right-buttons">
                            <slot name="right-buttons"></slot>

                            <template v-if="!minimized">
                                <b-btn variant="light" v-if="!minimized" @click="minimized = true" class="toggle-minimize"><i class="fas fa-window-minimize"/></b-btn>
                                <template v-if="isFullscreenAllowed && !fullscreen">
                                    <b-btn variant="light" v-if="!fullscreenEnabled" @click="fullscreenEnabled = true" class="toggle-fullscreen"><i class="fas fa-arrows-maximize"/></b-btn>
                                    <b-btn variant="light" v-if="fullscreenEnabled" @click="fullscreenEnabled = false" class="toggle-fullscreen"><i class="fas fa-arrows-minimize"/></b-btn>
                                </template>
                                <b-btn variant="light" v-if="iframeAllowOpenExternal" class="open-external" @click="hide" :href="frameUrl" target="_blank"><i class="fas fa-external-link"/></b-btn>
                                <b-btn variant="light" class="close" @click="hide" v-if="!noClose"><i class="fas fa-close"/></b-btn>
                            </template>
                            <b-btn variant="light" v-else @click="minimized = false" class="toggle-minimize"><i class="fas fa-window-restore"/></b-btn>
                        </div>
                    </slot>
                </div>
                <div v-show="!minimized" class="url" v-if="canAdminEyeInWireless() && urlVisible">
                    <b-input class="url-bar" :value="iframeSrc" readonly/>
                </div>
                <iframe v-show="!minimized" v-if="visible" ref="iframe" :id="iframeId" :class="[iframeClass]" :src="iframeSrc" :name="iframeId" :allowfullscreen="allowImageFullscreen"/>
                <div v-show="!minimized" class="footer" v-if="!noFooter">
                    <slot name="footer"></slot>
                </div>
            </div>
        </dialog>
    </div>
</template>

<script>
    import IframeHelper from "../helpers/IframeHelper.js";

    export default {
        name: `e-iframe-dialog`,
        props: {
            value: { type: Boolean },
            src: { type: String },
            preset: { type: [String, Object], default: null },
            dealerId: { type: String },
            itemId: { type: [Number, String] },
            itemType: { type: [Number, String] },
            url: { type: String },
            title: { type: String },
            glyph: { type: String },
            icon: { type: String },
            queryParams: { type: String },
            urlParams: { type: Object },
            iframeClass: { type: String },
            noFullscreen: { type: Boolean, default: true },
            allowOpenExternal: { type: Boolean },
            fullscreen: { type: Boolean },
            allowImageFullscreen: { type: Boolean },
            noEmbedFlag: { type: Boolean },
            noHeader: { type: Boolean },
            noClose: { type: Boolean },
            fillHeight: { type: Boolean },
            noFooter: { type: Boolean, default: true },
            noCloseOnBackdrop: { type: Boolean, default: true },
            size: {type: String, default: `md`},
            width: {type: [Number, String]},
            height: {type: [Number, String]}
        },
        data() {
            return {
                iframeId: generateGuid(),
                interval: null,
                urlVisible: false,
                fullscreenEnabled: false,
                iframeListener: null,
                minimized: false
            }
        },
        computed: {
            visible: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit(`input`, value);
                }
            },
            isFullscreenAllowed() {
                if (this.urlPreset) {
                    return this.urlPreset.allowFullscreen;
                } else {
                    return !this.noFullscreen;
                }
            },
            isFullscreenEnabled() {
                if (this.urlPreset) {
                    return this.urlPreset.fullscreen || this.fullscreenEnabled;
                } else {
                    return this.fullscreen || this.fullscreenEnabled;
                }
            },
            urlPreset() {
                if (this.preset && typeof this.preset === `object`) {
                    return this.preset;
                } else if (this.preset) {
                    const preset = IframeHelper.getPreset(this.preset);
                    if (!preset) {
                        console.warn(`Iframe preset '${this.preset}' does not exists for e-iframe-dialog`);
                        return null;
                    } else {
                        return preset;
                    }
                } else {
                    return null;
                }
            },
            iframeIcon() {
                if (this.urlPreset && this.urlPreset.icon) {
                    return getPublicIcon(this.urlPreset.icon);
                } else {
                    return this.icon;
                }
            },
            iframeSize() {
                if (this.urlPreset) {
                    return this.urlPreset.size;
                } else {
                    return this.size;
                }
            },
            contentStyle() {
                let style = {};
                if (this.urlPreset && (this.urlPreset.width || this.urlPreset.height)) {
                    if (this.urlPreset.width) {
                        style.width = this.urlPreset.width;
                    }
                    if (this.urlPreset.height) {
                        style.height = this.urlPreset.height;
                    }
                } else {
                    if (this.width) {
                        style.width = this.width;
                    }
                    if (this.height) {
                        style.height = this.height;
                    }
                }
                return style;
            },
            iframeAllowOpenExternal() {
                if (this.canAdminEyeInWireless()) {
                    return true;
                }
                if (this.urlPreset) {
                    return this.urlPreset.allowOpenExternal
                } else {
                    return this.allowOpenExternal;
                }
            },
            iframeFillHeight() {
                if (this.urlPreset) {
                    return this.urlPreset.fillHeight
                } else {
                    return this.fillHeight;
                }
            },
            iframeGlyph() {
                if (this.urlPreset) {
                    return this.urlPreset.glyph;
                } else {
                    return this.glyph;
                }
            },
            iframeTitle() {
                if (this.urlPreset) {
                    return this.urlPreset.title;
                } else {
                    return this.title;
                }
            },
            iframeSrc() {
                if (this.urlPreset) {
                    if (this.urlPreset.url === `{{url}}`) {
                        return this.url;
                    }
                }

                let url = this.frameUrl;
                if (url && !this.noEmbedFlag) {
                    if (url.indexOf(`?`) === -1) {
                        url += `?`;
                    } else {
                        url += `&`
                    }
                    url += `embed=true`;
                }
                return url;
            },
            frameUrl() {
                let url = this.src;
                if (this.urlPreset) {
                    if (this.urlPreset.url === `{{url}}`) {
                        return this.url;
                    }
                    url = this.urlPreset.url.replace(`{{id}}`, this.itemId || ``);
                    url = url.replace(`{{type}}`, this.itemType || ``);
                    url = url.replace(`{{dealerid}}`, encodeURIComponent(this.dealerId || ``));
                    if (this.urlParams) {
                        for (let param in this.urlParams) {
                            url = url.replace(`{{${param}}}`, encodeURIComponent(this.urlParams[param]));
                        }
                    }
                }
                if (this.queryParams) {
                    if (url.indexOf(`?`) === -1) {
                        url += `?`;
                    } else {
                        url += `&`
                    }
                    url += `${this.queryParams}`;
                }
                return url;
            }
        },
        mounted() {
            this.iframeListener = window.addEventListener(`message`, this.onMessageReceived.bind(this), false);
        },
        beforeDestroy() {
            if (this.iframeListener) {
                window.removeEventListener(`message`, this.iframeListener);
                this.iframeListener = null;
            }
        },
        methods: {
            onMessageReceived(event) {
                if (event.type !== `message` || event.data.type !== `e-iframe-dialog` || !event.data.action) return;

                console.log(event);

                if (event.data.iframeId !== this.iframeId) {
                    return;
                }

                switch (event.data.action) {
                    case `close`:
                        this.hide();
                        break;
                    case `refresh`:
                        this.$emit(`refresh`);
                        break;
                    case `created`:
                        this.$emit(`created`, event.data.item);
                        if (event.data.autoClose === true) {
                            this.hide();
                        }
                        break;
                    case `saved`:
                        this.$emit(`saved`, event.data.item);
                        if (event.data.autoClose === true) {
                            this.hide();
                        }
                        break;
                    default:
                        this.$emit(`iframe-message`, event.data);
                        break;
                }
            },
            show() {
                this.visible = true;
                this.$emit(`show`);
            },
            hide() {
                this.visible = false;
                this.$emit(`close`);
            },
            backdropClicked() {
                if (!this.noCloseOnBackdrop) {
                    this.hide();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.e-iframe-dialog {
    cursor: default;
    text-align: left;

    dialog {
        position: fixed;
        z-index: 10000;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        justify-content: center;
        align-items: center;

        &.visible {
            display: flex;
        }

        &.fullscreen {
            .content {
                left: 0 !important;
                top: 0 !important;
                width: 100% !important;
                height: 100% !important;

                iframe {
                    width: 100% !important;
                    height: 100% !important;
                    flex: 1;
                }
            }
        }

        &.size-xs {
            .content {
                width: 600px;
            }
        }

        &.size-sm {
            .content {
                width: 800px;
            }
        }

        &.size-lg {
            .content {
                width: 1600px;
                height: 800px;
            }
        }

        &.fill-height {
            .content {
                height: calc(100vh - 20px);
            }
        }
    }

    .overlay {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(black, 0.7);
    }

    .content {
        z-index: 1;
        position: relative;
        width: 1280px;
        height: 720px;
        max-width: 100%;
        max-height: 100%;
        background: #fff;
        border-radius: 5px;
        display: flex;
        flex-direction: column;

        > :first-child {
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;
        }

        > :last-child {
            border-bottom-left-radius: inherit;
            border-bottom-right-radius: inherit;
        }

        .header {
            display: flex;
            width: 100%;
            padding: 10px 10px 10px 20px;
            border-bottom: 1px solid #ddd;
            align-items: center;

            .favicon {
                width: 32px;
                max-height: 32px;
            }

            .title {
                flex: 10;
            }

            h4 {
                margin: 0;
            }

            .right-buttons {
                display: flex;

                .btn {
                    border-radius: 5px;
                    background: #ddd;
                    width: 40px !important;
                    height: 40px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-shadow: none;
                    color: #000;
                    opacity: 1;

                    & + .btn {
                        margin-left: 5px;
                    }

                    &:hover {
                        background: #bbb;
                    }
                }
            }
        }

        .url-bar {
            border-radius: 0 !important;
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
            border-bottom: 1px solid #ddd !important;
            background: white;
            color: #555 !important;
        }

        .footer {
            display: flex;
            width: 100%;
            padding: 10px;
            justify-content: flex-end;
            border-top: 1px solid #ddd;
            align-items: center;
        }

        iframe {
            border: none;
            width: 100%;
            height: 100%;
        }
    }

    dialog.minimized {
        position: fixed;
        left: auto;
        right: 15px;
        top: auto;
        bottom: 0;
        width: 400px;
        padding: 0;
        margin: 0;

        --minimized-height: 60px;

        height: var(--minimized-height);

        .content {
            height: var(--minimized-height);
            box-shadow: 0 -5px 15px rgba(black, 0.15);
            border: 1px solid #ccc;
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}
</style>
