<template>
    <b-button-group class="btn-views">
        <b-btn :disabled="disabled" variant="primary" class="btn-view"  :key="viewCode" v-for="(view, viewCode) in views" @click="clicked(viewCode)" :title="view.title" :class="{ 'active': viewCode === value }">
            <i :class="['fas ' + view.icon]"></i>
        </b-btn>
    </b-button-group>
</template>

<script>
    export default {
        name: `btn-view`,
        props: {
            value: {type: String},
            defaultView: {type: String},
            disabled: {type: Boolean},
            showPortraitIcons: {type: Boolean}
        },
        computed: {
            views() {
                let views = {
                    "list": {
                        icon: `fa-list`,
                        title: `Small list`
                    },
                    "large-list": {
                        icon: `fa-table-list`,
                        title: `Large list`
                    },
                    "small-icons": {
                        icon: `fa-table-cells`,
                        title: `Small Icons`
                    },
                    "icons": {
                        icon: `fa-table-cells-large`,
                        title: `Medium Icons`
                    },
                    "large-icons": {
                        icon: `fa-image`,
                        title: `Large Icons`
                    }
                };

                if (this.showPortraitIcons) {
                    views[`portrait-icons`] = {
                        icon: `fa-image-portrait`,
                        title: `Portrait Icons`
                    };
                }
                return views;
            }
        },
        methods: {
            clicked(value) {
                this.$emit(`input`, value);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .btn-group .btn.btn-view {
        border-radius: 4px !important;
        padding: 0 2px;
        font-size: 18px;
        height: 26px;
        width: 26px;
        line-height: 1;
        margin-left: 2px;
        color: inherit;
        z-index: 0 !important;
        border: none;

        &:not(.active) {
            background: transparent;
        }

        &.active {
            color: #fff;
            border: 1px solid rgba(255, 255, 255, 0.3);
        }
    }
</style>
