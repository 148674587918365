import {translate} from "./i18n.js";
import {getNestedValue} from "./utils.js";
import {date, datetime, time} from "./vue-filters.js";

export default class BrowserHelper {
    /**
     * Return a value formatted for display
     * @param item {Object}
     * @param field {Object} must contain key, label, format, formatter...
     * @param mode {String} display|export
     * @returns {String|*}
     */
    static getFormattedValue(item, field, mode = `display`) {
        let nestedValue = getNestedValue(item, field.key);
        if (nestedValue !== undefined) {
            if (typeof field.formatter === `function`) {
                nestedValue = field.formatter(nestedValue, item, field);
            }

            if (mode === `export`) {
                if (typeof field.exportFunction === `function`) {
                    nestedValue = field.exportFunction(nestedValue, item, field);
                }
            }

            let format = null;
            if (!field.format) {
                if (field.key.indexOf(`date`) > -1) {
                    format = `datetime`;
                } else if (field.key === `dealer` || field.key === `dealerid`) {
                    format = `dealerid`;
                }
            }

            switch (field.format || format) {
                // TODO add dealerid again from vue-filters
                // case `dealerid`:
                //     nestedValue = formatDealer(nestedValue);
                //     break;
                case `translation`:
                    nestedValue = translate(nestedValue);
                    break;
                case `date`:
                    nestedValue = date(nestedValue);
                    break;
                case `time`:
                    nestedValue = time(nestedValue);
                    break;
                case `datetime`:
                    nestedValue = datetime(nestedValue);
                    break;
            }
        }
        return nestedValue;
    }
}
