<template>
    <div class="input-image form-control" :class="`size-${size}`">
        <template v-if="!imageSrc">
            <b-btn variant="light-primary" @click="mediaLibraryVisible = true">
                <i class="fas fa-folder-image mr-1"/>
                <span v-tr>Add Image|Ajouter image</span>
            </b-btn>
        </template>

        <template v-else>
            <div class="d-flex">
                <b-img rounded :width="size === 'lg' ? 80: 40" height="40" style="object-fit: contain" class="mr-auto" :class="{'square': category === 'favicon'}" :src="imageSrc"/>
<!--                <b-btn v-if="showEdit" variant="light" @click="$emit('edit')" :title="'Edit Image|Modifier l\image'|tr" v-b-tooltip>-->
<!--                    <i class="fas fa-wand-magic-sparkles"/>-->
<!--                </b-btn>-->
                <b-btn variant="light" @click="mediaLibraryVisible = true" class="ml-2" :title="'Replace Image with another|Remplace l\image par une autre'|tr">
                    <span v-tr>Replace|Remplacer</span>
                </b-btn>
                <b-btn variant="light" @click="removeImage()" class="ml-2 text-danger" :title="'Remove Image|Enlever l\image'|tr" v-b-tooltip>
                    <i class="fas fa-trash"/>
                </b-btn>
            </div>
        </template>

        <e-file-library v-model="imageSrc" :visible.sync="mediaLibraryVisible" :category="category" source="cms-website" :source-id="cmsWebsite.id"/>
    </div>
</template>

<script>
import EFileLibrary from "../../../../../vue-components/components/e-file-library.vue";

// input-image #hot-reload-debug
export default {
    name: `input-image`,
    components: {EFileLibrary},
    props: {
        value: {type: [Object, String]},
        size: {type: String, default: `md`},
        category: { type: String, default: `image` },
        showEdit: {type: Boolean}
    },
    computed: {
        imageSrc: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        }
    },
    data() {
        return {
            mediaLibraryVisible: false
        }
    },
    methods: {
        imageSelected(imageSrc) {
            this.imageSrc = imageSrc;
        },
        removeImage() {
            this.imageSrc = null;
        }
    }
}
</script>

<style lang="scss" scoped>
.input-image {
    padding: 4px;
    height: 52px;

    img.square {
        //background: #eee;
        padding: 5px;
        border: 2px dashed #ccc !important;
    }

    &.size-xl {
        height: 102px;
        border: 2px dashed #ccc;

        .d-flex {
            img {
                height: 90px !important;
                width: auto !important;
            }
        }
    }
}
</style>
