<template>
    <div class="browser-category" :class="[ theme, 'view-' + view]">
        <div class="name"><slot name="name"><span :title="name">{{name}}</span></slot></div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: `e-browser-category`,
        props: {
            name: {type: String},
            view: String,
            icon: {type: String, default: getPublicIcon(`home`)},
            thumbnail: [String, Function],
            theme: String
        }
    }
</script>

<style lang="scss" scoped>
    .browser-category {
        display: inline-block;
        max-width: 100%;
        min-width: 250px;
        position: relative;
        text-align: left;
        margin-right: 10px;
        margin-bottom: 10px;
        border: 2px dashed rgba(black, 0.1);
        background: rgba(black, 0.07);
        border-radius: 5px;
        padding: 30px 5px 0px;

        .name {
            position: absolute;
            top: -4px;
            left: -4px;
            white-space: nowrap;
            padding: 3px 6px;
            max-width: calc(100% + 6px);
            margin-right: 10px;
            margin-bottom: 25px;
            overflow: hidden;

            text-transform: uppercase;
            color: #565656;
            letter-spacing: 0.5px;
            background-color: #ddd;
            border-radius: 4px;
        }
    }

    .view-small-icons {
        &.browser-category,
        .browser-category {
        }
    }

    .view-large-icons {
        &.browser-category,
        .browser-category {
        }
    }

    .view-large-list,
    .view-list {
        &.browser-category,
        .browser-category {
            display: block;
            width: 100%;
            text-align: left;
            padding: 15px 0 0;
            background: transparent;
            border: none;

            border-top: 2px dashed #ddd;
            border-radius: 0;
            margin-top: 10px;
            margin-bottom: 20px;

            ::v-deep {
                .name {
                    left: 15px;
                    top: -14px;
                }
            }
        }
    }

    .view-large-list {
        &.browser-category,
        .browser-category {
        }
    }

    .browser.translucent {
        .browser-category {
            border: 2px solid rgba(white, 0.1);
            background: rgba(black, 0.1);

            .name {
                color: #565656;
                background-color: #eee;
            }
        }
    }
</style>
